import React, { useState } from 'react';
import ThemeCardBase from './ThemeCardBase';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from '@atoms/new/dropdown';
import { type Theme, useThemeStore } from './store/ThemeStore';
import useThemesAPI from './hooks/useThemesAPI';
import ImageLoader from '@atoms/new/imageLoader';
import { usePurchasePlan } from '@lib/hooks/usePlanSettings';
import { PricingAlertNudgePopover } from '@components/PricingPlans/NudgesAlert';
import { useDisclosure } from '@nextui-org/react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

interface ThemeCardBaseProps {
  onClick?: () => void;
  theme: Theme
  isDefault?: boolean;
  isSelected?: boolean;
  handleDefaultTheme?: (themeId: string) => void;
  openThemeModal?: () => void;
  onDelete?: (themeId: string) => void;
  height?: string;
  // presentation page related props
  isPresentationPage?: boolean;
  isStandardTheme?: boolean;
  showMenu?: boolean;
  minWidth?: string;
}
export default function ThemeCard({
  onClick,
  onDelete,
  theme,
  isDefault = false,
  isSelected = false,
  handleDefaultTheme,
  openThemeModal,
  isPresentationPage = false,
  isStandardTheme = false,
  showMenu = true,
  height,
  minWidth
}: ThemeCardBaseProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const { settings: planSettings } = usePurchasePlan();

  const [, setStore] = useThemeStore(store => store.selectedTheme);

  const { createThemeApi } = useThemesAPI();

  const onDuplicate = async () => {
    if (!theme) return;
    await createThemeApi(theme);
  };

  const {
    isOpen: isNewThemeUpgradeAlertOpen,
    onOpen: onNewThemeUpgradeAlertOpen,
    onClose: onNewThemeUpgradeAlertClose
  } = useDisclosure();

  return (
    <ThemeCardBase
      onClick={onClick}
      isSelected={isSelected}
      height={height || isPresentationPage ? 'h-[137px]' : 'h-[178px]'}
      className={isPresentationPage ? ' hover:border-red-500' : ''}
      minWidth={minWidth}
    >
      <div className='flex flex-col size-full relative'>
        {isDefault && <div className='absolute text-xs font-medium bg-grey-200 h-5 px-1.5 m-3 rounded-[3px] z-[1] flex items-center'>Default</div>}
        <div
          style={{ background: theme.config.default.bgColor }}
          className='flex-1 flex items-center justify-center relative'>
          {theme.config.default.imageSrc && <ImageLoader
            src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${theme.config.default.imageSrc}`}
            style={{ opacity: theme.config.default.imageOpacity / 100 }}
            className='absolute size-full object-cover'
            alt='theme image'
            width={720}
            height={360}
          />}
          <p
            style={{
              color: theme.config.default.headingColor,
              fontFamily: theme.config.default.headingFontFamily,
            }}
            className='font-semibold text-lg z-[1]'>Heading</p>
        </div>
        <p className={`${isSelected ? 'bg-red-50' : 'bg-white'} ${showDropdown ? '' : ''} border-t border-grey-200 font-semibold text-sm pl-4 pr-1.5 h-10 text-start flex justify-between items-center`}>
          <span className='line-clamp-1 max-w-[200px] overflow-hidden'>
            {theme.name.length > 25 ? `${theme.name.substring(0, 22)}...` : theme.name}
          </span>
          {showMenu && <Dropdown
            isOpen={showDropdown}
            closeOnSelect={false}
            onClose={() => {
              setShowDropdown(false);
            }}
            placement='bottom-start'>
            <DropdownTrigger>
              <span
                onClick={() => setShowDropdown(true)}
                className='hover:bg-grey-200 rounded px-2 h-8 grid place-items-center cursor-pointer'>
                <MoreVertRoundedIcon fontSize='small'/>
              </span>
            </DropdownTrigger>
            <DropdownMenu className='w-60'>
              <DropdownSection showDivider={(!isPresentationPage && isStandardTheme)}
                className="!m-0"
                classNames={{
                  divider: 'my-1'
                }}>
                <DropdownItem
                  startContent={isPresentationPage ? <EditOutlinedIcon fontSize='small'/> : null}
                >
                  <div>
                    <PricingAlertNudgePopover
                      placement={isPresentationPage ? 'left-start' : 'right-start'}
                      offset={isPresentationPage ? 50 : 20}
                      crossOffset={-11}
                      isOpen={isNewThemeUpgradeAlertOpen}
                      onClose={onNewThemeUpgradeAlertClose}
                      title="Upgrade for custom themes"
                      description="Please upgrade to Starter or above to customize your themes."
                      trigger={(
                        <div
                          onClick={() => {
                            if (!planSettings?.themeCustomizationAllowed) {
                              onNewThemeUpgradeAlertOpen();
                              return;
                            }
                            setShowDropdown(false);
                            if (!openThemeModal) return;
                            let name = theme.name;
                            if (!theme.is_editable) name = 'Untitled theme';
                            setStore({
                              selectedTheme: {
                                ...theme,
                                config: { ...theme.config },
                                name
                              }
                            });
                            openThemeModal();
                          }}
                        >
                          <p className='text-sm text-grey-900'>Customize this theme</p>

                          {isStandardTheme && <p className='text-sm text-grey-700 text-wrap mt-1'>Duplicate this theme to edit it for your workspace.</p>}
                        </div>
                      )}
                    />
                  </div>
                </DropdownItem>
              </DropdownSection>
              <DropdownSection
                className="!m-0"
                classNames={{
                  divider: 'my-1'
                }}
                hidden={isStandardTheme}
                showDivider={isPresentationPage && !isStandardTheme}>
                <DropdownItem
                  onClick={() => {
                    onDuplicate();
                    setShowDropdown(false);
                  }}
                  startContent={isPresentationPage ? <ContentCopyRoundedIcon fontSize='small'/> : null}
                >
                Duplicate theme
                </DropdownItem>
              </DropdownSection>
              <DropdownSection
                className="!m-0"
                classNames={{
                  divider: 'my-1'
                }}
                hidden={isPresentationPage}
                showDivider={!isStandardTheme}>
                <DropdownItem
                  onClick={() => {
                    handleDefaultTheme?.(theme.id);
                    setShowDropdown(false);
                  }}
                  isDisabled={isDefault}>
                Set as default
                </DropdownItem>
              </DropdownSection>
              <DropdownSection className='mb-0'
                hidden={isStandardTheme}
              >
                <DropdownItem
                  onClick={() => {
                    onDelete?.(theme.id);
                    setShowDropdown(false);
                  }}
                  isDisabled={isStandardTheme}
                >
                  <div className="flex items-center gap-2 text-pure-red-600">
                    <DeleteOutlinedIcon fontSize="small" />
                    <span>Delete</span>
                  </div>
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>}

        </p>
      </div>
    </ThemeCardBase>
  );
}
